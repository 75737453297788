main {
  /* background-color: beige; */
  background-color: whitesmoke;
  margin: 10px 10px;
  padding: 20px 20px;
  /* border: 2px solid grey; */
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  /* border-radius: 5px; */
}

/* ------------------------------------------------------------------------------- */

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  margin: 0px 100px;
  margin-top: 50px;
  /* margin-left: 500px; */

  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */

  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* grid-gap: 50px; Add some space between images */
  align-items: center;
}
.content img {
  /* max-width: 100%; */
  height: 200px; /* Maintain aspect ratio */
  width: 100%;
}

.content .card {
  width: 200px;

  height: 400px;
  /* padding: 50px; */
  transition: transform 0.3s;
}

.content h5 {
  height: 50px;
  border-bottom: 2px solid grey;
}
.content .card:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .content {
    margin: 0px 2px;
    margin-top: 5px;
    gap: 10px;
  }

  .content .card:hover {
    transform: scale(1.02);
  }
  .content .card {
    width: 150px;

    height: 320px;
    /* padding: 50px; */
    /* transition: transform 0.3s; */
  }
  .content img {
    /* max-width: 100%; */
    height: 150px; /* Maintain aspect ratio */
    width: 100%;
  }
  .content h5 {
    height: 40px;
    font-size: smaller;
    border-bottom: 2px solid grey;
  }

  .content p {
    height: 60px;
    margin-bottom: 5px;
    font-size: smaller;
    /* border-bottom: 2px solid grey; */
  }
  .content .card a {
    padding: 2px 5px;
    font-size: smaller;
    height: 27px;
  }
  .card-body {
    height: 150px;
  }
}

/* --------------------------------------------------------------------------- */

.blog_title {
  text-align: center;
  font-size: 30px;
  color: grey;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 50px;
}
.blog_articles {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

#blog_items {
  width: 400px;
  height: 400px;
  text-align: center;
}

#blog_items img {
  height: 250px;
}

#blog_items a {
  text-decoration: none;
  color: goldenrod;
}
.blog_article_page_image img {
  height: 600px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 600px) {
  .blog_title {
    text-align: center;
    font-size: 15px;
    color: grey;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    padding: 20px;
  }
  .blog_articles {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
  }

  #blog_items {
    width: 150px;
    height: 200px;
    text-align: center;
  }

  #blog_items img {
    height: 100px;
  }

  #blog_items a {
    text-decoration: none;
    color: goldenrod;
    font-size: 10px;
  }
  #blog_items h5 {
    font-size: 10px;
  }
  #blog_items .card-body {
    height: 100px;
  }
  .blog_article_page_image {
    height: 300px;
  }
  .blog_article_page_image img {
    height: 5300px;
  }
}

/* -------------------------------------------------------------------------- */

.contacts-group {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.contacts-group a {
  text-decoration: none;
}
.group-1,
.group-2 {
  display: flex;
  gap: 50px;
}
.group-2 {
  flex-wrap: wrap;
}

.contacts-group .card-text img {
  width: 40px;
  height: 40px;
  /* padding-top: 5px; */
  margin-top: 10px;
}

.contacts-group .card {
  width: 500px;
  height: 300px;
  padding: 50px;
  transition: transform 0.3s;
}

.contacts-group .card:hover {
  transform: scale(1.1);
}

.contactus-address {
  /* font-size: smaller; */
  width: 250px;
}

@media (max-width: 600px) {
  .group-1,
  .group-2,
  .contacts-group {
    flex-direction: column;
    gap: 20px;
  }
  .contacts-group .card {
    width: 300px;
    height: 220px;
    padding: 20px 0px;
    font-size: smaller;
  }
  .contactus-address {
    /* font-size: smaller; */
    width: 250px;
  }
  .contacts-group .card-text img {
    width: 30px;
    height: 30px;
    /* padding-top: 5px; */
    margin-top: 10px;
  }
}

/* ------------------------------------------------------------------------------ */
.details_flower {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.details_flower_images {
  display: flex;
  gap: 40px;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.details_flower_images img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.details_flower_description {
  text-align: center;
  margin-top: 10px;
  width: 60%;
}

.details_flower_description h1 {
  padding-bottom: 40px;
  border-bottom: 5px dashed goldenrod;
  font-family: "Times New Roman", Times, serif;
}
.details_flower_description p {
  margin: 20px 0px;
  text-align: justify;
}

@media (max-width: 600px) {
  .details_flower {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: smaller;
  }
  .details_flower_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
    align-items: center;
    justify-content: center;
  }

  .details_flower_images img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }

  .details_flower_description {
    text-align: center;
    margin-top: 5px;
    width: 100%;
  }

  .details_flower_description h1 {
    padding-bottom: 10px;
    border-bottom: 5px dashed goldenrod;
    font-family: "Times New Roman", Times, serif;
  }
  .details_flower_description p {
    margin: 10px 0px;
    text-align: justify;
  }
}

/* ------------------------------------------------------------------------------------ */
.form {
  width: 50%;
  background-color: rgb(236, 234, 234);
  padding: 20px 40px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .form {
    width: 100%;
    padding: 5px 10px;
  }
  .form .promotional {
    /* display: inline-block; */
    /* width: 300px; */
    /* display: flex; */
    display: none;
  }
}

/* ------------------------------------------------------------------------- */

.privacy_policy {
  width: 70%;
  margin-left: 15%;
  text-align: justify;
  font-weight: 100;
}

.privacy_policy h3 {
  text-align: center;
  padding: 10px;
}
.privacy_policy .last_updated {
  font-size: x-small;
}

@media (max-width: 800px) {
  .privacy_policy {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
    text-align: justify;
    font-weight: 100;
    font-size: smaller;
  }

  .privacy_policy h3 {
    text-align: center;
    padding: 5px;
  }
  .privacy_policy .last_updated {
    font-size: xx-small;
  }
}
