.circular-icons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* margin-left: 20%; */
  gap: 20px;
  transition: transform 0.3;
  justify-content: center;
  }
  .circular-icons a {
      color: grey;
  }
  
  .icon-container:hover {
      transform: scale(1.05);
  }
  .circular-icons .icon{
      width: 80px;
      height: 80px;
      border-radius: 40px;
  }
  .icon-container {
      background-color: whitesmoke;
      text-align: center;
      transition: transform 0.2s;
  
  }
  