/*--------------------------------------------------- banner */

.first_banner {
  width: 100%;
  height: 500px;
}

.first_banner img {
  width: 100%;
  height: 500px;
  margin: 0px 0px;
  padding: 0px 0px;
  object-fit: cover;
  object-position: right;
}

@media (max-width: 600px) {
  .first_banner {
    width: 100%;
    height: 200px;
  }

  .first_banner img {
    width: 100%;
    height: 200px;
    margin: 0px 0px;
    padding: 0px 0px;
  }
}
