#carouselExampleIndicators {
  /* padding: 20px 0px; */
  /* padding-bottom: 50px; */
  margin: 20px 0px;
  /* height: 100px; */
}

.carousel-item img {
  padding: 5px 300px;
  border-radius: 5px;
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
  /* border: 2px solid black; */
  /* border-radius: 5px; */
}

@media (max-width: 600px) {
  #carouselExampleIndicators {
    margin: 10px 0px;
  }

  .carousel-item img {
    padding: 5px 5px;
    border-radius: 5px;
    width: 100%;
    height: 160px;
    /* object-fit: cover; */
    /* border: 2px solid black; */
  }
  .navbar-brand {
    font-size: smaller;
    font-weight: 600;
  }
  #navbarSupportedContent li {
    font-size: smaller;
  }
}
@media (max-width: 1200px) and (min-width: 601px) {
  .carousel-item img {
    padding: 5px 20px;
    border-radius: 5px;
    width: 100%;
    height: 230px;
    /* object-fit: cover; */
    /* border: 2px solid black; */
    /* border-radius: 5px; */
  }
}

.carousel_and_content {
  /* color: orange; */
  /* display: inline-flex; */
  /* gap: 10px; */
  /* margin-left: 30%; */
  background-color: goldenrod;
}
.sidepanel {
  background-color: whitesmoke;
  width: 20%;
  margin-left: 50px;
  height: 600px;
  z-index: 2;
  /* position:static; */
  position: fixed;
  top: 180px;
  /* padding-right: 2px;
  border-right: 1px goldenrod dotted; */
  /* font-size: x-small; */
}
.sidepanel h5 {
  /* color: orange; */
  border-bottom: 1px solid grey;
  padding: 2px 0px;
  padding-left: 110px;
  color: black;
  font-size: smaller;
  margin: 0px 0px;
  /* font-size: x-small; */
}

.sidepanel a {
  color: black;
}
.sidepanel p {
  /* color: orange; */
  /* border-bottom: 1px solid grey; */
  padding-left: 115px;
  font-size: smaller;

  margin: 0px 0px;
}
/* @media (min-width:1301px) and (max-width:1400px) {
  .sidepanel {
      font-size: x-small;
  } */
/* } */
@media (max-width: 1400px) {
  .sidepanel {
    display: none;
  }
  .carousel_and_content {
    /* width: 100%; */
    margin-left: 0%;
  }
}
