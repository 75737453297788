.brand-group{
  display: flex;
  /* background-color: rgb(233, 212, 157); */
  background-color: white;

}
.brand, .brand-contacts {
  display: flex;
  align-items: center;
  gap:0px;
  padding-top: 5px;
  
}
.brand-contacts{
  border-left:2px dotted goldenrod;
  margin-left: 10px;
  align-items:center;
  justify-content: center;
  
}
.brand-contacts img {
  padding-bottom: 15px;
}
/* .brand-contacts p {
  /* width: 148px; */

.brand img {
  margin-left: 20px;
  width: 80px;
  height: 80px;

}

.brand h1 {
  padding: 20px 0px;
  margin-left: 20px;
  font-size: 40px;
  font-family:'Times New Roman', Times, serif;
  color: rgb(164, 7, 7);
}
.brand-contacts img {
  /* transform: scale(0.5);

   */
   
   /* text-align: center; */
   width: 30px;
   margin-left: 10px;
   margin-right: 10px;
}

.brand-contacts p {
  /* transform: scale(0.5);

   */
   
   /* text-align: center; */
   /* width: 30px; */
   margin-left: 5px;
   margin-right: 30px;
   /* margin-top: 5px; */
}
.brand-contacts h5 {
  opacity: 0.5;
   font-size: xx-large;
   
}
a {
  text-decoration: none;
}
.navbar {
  background-color: goldenrod;
  position: static;
}



@media (min-width:651px) and (max-width:1120px) {
  .brand h1 {
      font-size: 20px;
      margin-left:20px ;
  }
  .brand {
      width: auto;
  }
  .brand-contacts {
      flex-direction: row;
      font-size: smaller;
  }
  
  /* .brand-contacts img {
      /* transform: scale(0.5);
  
       */
       
       /* text-align: center; */
       /* width: 30px;
       margin-left: 10px;
       margin-right: 5px; */
  /* }  */
  
}



/* @media (max-width:650px){ */
  @media (max-width:860px){
  .brand-group {
      flex-direction: column;
  }
  .brand-contacts{
      border-top: 2px dotted goldenrod;
      border-left: 0px;
      /* padding-bottom: 0px; */
      padding-top: 10px;
      margin: 0px 0px;
      font-size: x-small;
      gap: 0px;
      align-items:flex-start;

  }
  .brand-contacts img {
    
       width: 15px;
       margin-left: 5px;
       margin-right: 5px;
  }
  .brand-contacts h5 {
      font-size: small;
      margin-top: 5px;

  }
  .brand-contacts p {
    
      /* width: 15px; */
      margin-left: 5px;
      margin-right: 10px;
 }

  .brand h1 {
      font-size: 20px;
      margin-left: 15px;
  }
  .brand img{
      transform: scale(0.8);
  }
  


}