footer{
  background-color: rgb(235, 232, 232);
  text-align: center;
  z-index: 3;
  position: relative;

}

.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}


.footer-element {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 250px;
  text-align: center;
  /* justify-content: center; */
  /* justify-items: center; */
  align-items: center;
  padding: 20px 20px;
  /* background-color: rgb(195, 194, 194); */
}
.footer-element h4 {
  margin-right: 7px;
}

.footer div {
  display: flex;
  text-align: center;
  padding-bottom: 10px;
}

.footer img {
  width: 40px;
  height: 30px;
  padding-right: 10px;
}

.footer p {
  border-top: 3px solid grey;
  padding-top: 20px;
}

footer p {
  display: block;
  border-top: 2px solid grey;
  padding: 10px 0px;
  font-size: smaller;
}
@media (min-width:601px) and (max-width:1000px){
.footer {
  font-size: smaller;
}
.footer {

  flex-wrap:wrap ;
  gap: 20px;
  padding-top: 10px;
  font-size: smaller;

}    
.footer-element {
  /* height: auto; */
  padding: 2px 2px;
}
.footer p {
  padding: 2px;
  /* font-size: smaller; */
}
footer p {
  display: block;
  border-top: 2px solid grey;
  padding: 5px 0px;
  font-size: x-small;
}
}

@media (max-width:600px){

.footer {

  flex-wrap:wrap ;
  gap: 20px;
  padding-top: 10px;
  font-size: smaller;

}    
.footer-element {
  height: auto;
  padding: 2px 2px;
}
.footer p {
  padding: 2px;
  /* font-size: smaller; */
}
footer p {
  display: block;
  border-top: 2px solid grey;
  padding: 5px 0px;
  font-size: x-small;
}

}
  