.blog_title {
  text-align: center;
  font-size: 30px;
  color: grey;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 50px;
}
.blog_articles {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

#blog_items {
  width: 400px;
  height: 400px;
  text-align: center;
}

#blog_items img {
  height: 250px;
}

#blog_items a {
  text-decoration: none;
  color: goldenrod;
}
.blog_article_page_image img {
  height: 600px;
}
@media (max-width: 600px) {
  .blog_title {
    text-align: center;
    font-size: 15px;
    color: grey;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    padding: 20px;
  }
  .blog_articles {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
  }

  #blog_items {
    width: 150px;
    height: 200px;
    text-align: center;
  }

  #blog_items img {
    height: 100px;
  }

  #blog_items a {
    text-decoration: none;
    color: goldenrod;
    font-size: 10px;
  }
  #blog_items h5 {
    font-size: 10px;
  }
  #blog_items .card-body {
    height: 100px;
  }
  .blog_article_page_image {
    height: 300px;
  }
  .blog_article_page_image img {
    height: 300px;
  }
}
